import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://862a35f72f4828f9e62d435695e9c65a@o4505765662031872.ingest.us.sentry.io/4507641523732480",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});